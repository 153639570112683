.rbc-header {
    color: white;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px !important;
    background-color: #183540;
}

.rbc-allday-cell {
    margin-top: 15px;
}

.rbc-time-header-cell .rbc-today {
    background-color: rgb(29, 145, 166);
}

.rbc-time-slot {
    color: #7897a3;
}


/* EVENT STYLES */

.rbc-event,
.rbc-background-event {
    z-index: 2;
}

.rbc-event-label {
    font-size: 100%;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.rbc-events-container {
    width: 100%;
}

.rbc-day-slot .rbc-event {
    border: 2px solid rgb(210, 240, 244);
    display: flex;
}

.rbc-day-slot .rbc-event-label {
    font-size: 12px;
}

.rbc-day-slot .rbc-event-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

@media (max-width: 600px) {
    .rbc-header {
        color: white;
        font-size: 10px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        height: 36px !important;
        background-color: #183540;
        white-space: normal;
    }

    .rbc-event {
        padding: 0;
        color: black;
    }

    .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
        justify-content: center;
        font-weight: 400;
        font-size: 10px;
        color: transparent
    }

    .rbc-row-segment {
        padding: 0 3px 2px 3px;
    }

    .rbc-day-slot .rbc-event-content {
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        justify-content: center !important;
        flex-direction: row;
    }

    .rbc-day-slot .rbc-event-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .rbc-time-header.rbc-overflowing element.style{
        margin-right: 0px;
    }

    .rbc-btn-group > button  {
        padding: 5px 10px;
        font-size: medium;
        margin: 4px 0;
    }

  }

