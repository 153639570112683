// Estilo personalizado para datatable

// .rdt_Table{

//   border: 1px solid #ddd;

// }
// .rdt_Table .rdt_TableHeadRow{
//   background-color: #eff2f7;
// }


.rdt_TableCol div:first-child {
  white-space: normal !important;
  overflow: visible !important;
}
/* Estilo para el contenedor del Dropdown (n° de registros por págica) */
.dropdown-container {
    display: flex;
    justify-content: flex-start; 
  }

.paginator-outer-container {
    
    overflow-x: auto; 

  }
  
  .paginator-inner-container {
    
    display: inline-block; 
    white-space: nowrap; 
    overflow-x: auto;
    min-width: 100%; 
    
    
  }

  .page-item{
   
    align-self: center;
    
  }

  .page-item .page-link{
    
  
    
    border:1px solid rgb(206, 201, 201);
    
  }
