// Definición del estilo para los enlaces de las tabs
.nav-tabs {
    .nav-link {
        background-color: transparent !important;
       
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        
      // Estilo para el tab activo
      &.active {
        // border-bottom: solid 2px blue !important;
        color: white !important;
        background-color: rgb(43, 43, 236) !important;
      }
    }
  }