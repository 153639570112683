
#sidebar-menu {

  ul {
    li {
      a {
        span.menu-title-1 {
          text-transform: uppercase;
        }
      }
      ul.sub-menu {
        li {
          a {
            padding-left: 2.2rem;
          }

          ul.sub-menu {
            li {
              a {
                padding-left: 3.2rem;
              }
            }
          }
        }
      }
    }
  }
}


.question-label {
  
  //display: flex;
  align-items: center;
  align-content: center;
  word-wrap: break-word;
}


.mobile-wrapper {
  position: fixed;
  bottom:0;
  z-index: 100;
  width: 80%;
  background-color: white;
  height: 70px;
  padding: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .mobile-wrapper {
    position: fixed;
    bottom:0;
    width: 100%;
    z-index: 100;
    background-color: white;
    height: 70px;
    padding: 0;
    display: flex;
    align-items: center;
  }
}

